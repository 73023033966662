import * as React from "react";
import clsx from "clsx";
import * as yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import FormikTextField from "./components/FormikTextField";
import FormikTextareaField from "./components/FormikTextareaField";
import { contactAPI } from "./api/siteApi";
import axiosDrfErrorHandler from "./api/axiosDrfErrorHandler";

type ContactFormValues = {
  name: string;
  subject: string;
  email: string;
  message: string;
};
const validationSchema = yup.object().shape({
  name: yup.string().required(),
  subject: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().required(),
});
function ContactForm() {
  const documentLang = document.documentElement.lang;
  const initValues = {
    name: "",
    subject: "",
    email: "",
    message: "",
  };
  function handleSubmit(
    values: ContactFormValues,
    actions: FormikHelpers<ContactFormValues>
  ) {
    contactAPI
      .post("", values, {
        headers: {
          "Accept-Language": documentLang,
        },
      })
      .then((response) => {
        const { data: resData } = response;
        // 성공 메시지 표시
        actions.resetForm();
        actions.setStatus({ formSubmitted: true });
      })
      .catch((errorResponse) => {
        axiosDrfErrorHandler(errorResponse, actions, values);
      });
  }
  function createDismissStatusMessage(setStatusFunc: (status?: any) => void) {
    return function () {
      setStatusFunc({});
    };
  }
  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ status, isSubmitting, setStatus }) => (
        <Form>
          <h2 className="sr-only">Contact Us</h2>
          <div className="form-row">
            <div className="col-lg-6">
              <FormikTextField label="Name" name="name" size="lg" />
            </div>
            <div className="col-lg-6">
              <FormikTextField label="Email" name="email" size="lg" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-12">
              <FormikTextField label="Subject" name="subject" size="lg" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-12">
              <FormikTextareaField
                label="Message"
                name="message"
                size="lg"
                rows={6}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              disabled={isSubmitting}
              className={clsx("btn btn-lg btn-primary")}
            >
              {isSubmitting ? (
                <React.Fragment>
                  <span
                    className="spinner-border spinner-border-sm pr-2"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </React.Fragment>
              ) : (
                "Send Message"
              )}
            </button>
          </div>
          {status?.generalErrorMessage && (
            <div className="mt-2">
              <div className="message-box bg-danger">
                <div className="icon-col pr-3">
                  <span className="material-icons text-white">
                    error_outline
                  </span>
                </div>
                <div className="message-col text-white">
                  {status.generalErrorMessage}
                </div>
              </div>
            </div>
          )}
          {status?.formSubmitted && (
            <div className="mt-2">
              <div className="message-box bg-success-light">
                <div className="icon-col pr-3">
                  <span className="material-icons text-success">
                    check_circle
                  </span>
                </div>
                <div className="message-col text-success-dark">
                  <div className="mb-3">
                    Your message was successfully submitted. Thank you.
                  </div>
                  <button
                    className="btn btn-outline-default btn-sm"
                    onClick={createDismissStatusMessage(setStatus)}
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ContactForm;
