import { BreakPoints, BREAKPOINTS } from "./constants";

export function getCurrentWindowWidth(): number {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
}

export function getCurrentScreenSize(): BreakPoints {
  const width = getCurrentWindowWidth();
  if (width < BREAKPOINTS.xs) {
    return "xs";
  }
  if (width < BREAKPOINTS.sm) {
    return "sm";
  }
  if (width < BREAKPOINTS.md) {
    return "md";
  }
  if (width < BREAKPOINTS.lg) {
    return "lg";
  }
  return "xl";
}

export function checkIfMobile(): boolean {
  const curWidth = getCurrentWindowWidth();
  return curWidth <= BREAKPOINTS.sm;
}

export function getCookie(name: string): string | null {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
