import * as React from "react";
import clsx from "clsx";
import { useField } from "formik";

type FormikTextFieldProps = {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  helpText?: string;
  size?: "default" | "lg" | "sm";
  autoComplete?: "off" | "on" | "email";
};

function FormikTextField(props: FormikTextFieldProps) {
  const {
    name,
    label,
    type = "text",
    placeholder = "",
    helpText = "",
    size = "default",
    autoComplete = "off",
  } = props;
  const id = `id-${name}`;
  const [field, meta] = useField(name);
  const hasError = meta.error && meta.touched;
  return (
    <div className={clsx("form-group", hasError && "has-error")}>
      <label htmlFor={id} className="label">
        {label}
      </label>
      <input
        id={id}
        type={type}
        className={clsx(
          "form-control",
          size !== "default" && `form-control-${size}`
        )}
        {...field}
        autoComplete={autoComplete}
        placeholder={placeholder}
      />
      {helpText && <div className="help-text">{helpText}</div>}
      {hasError && <div className="error-message">{meta.error}</div>}
    </div>
  );
}

export default FormikTextField;
