import { has, forIn } from "lodash";
import { FormikHelpers } from "formik";
import { AxiosError } from "axios";

function axiosDrfErrorHandler<SubmittedValues>(
  axiosError: AxiosError,
  formikActions: FormikHelpers<SubmittedValues>,
  submittedValues: SubmittedValues
): void {
  // https://github.com/axios/axios#handling-errors
  const { response } = axiosError;
  formikActions.setSubmitting(false);
  let generalErrorMessage = "";
  if (response) {
    const { data } = response;
    forIn(data, (value, key) => {
      if (key === "nonFieldErrors") {
        generalErrorMessage += value.join("\n");
      } else if (submittedValues) {
        if (has(submittedValues, key)) {
          formikActions.setFieldError(key, value.join("\n"));
        } else {
          generalErrorMessage += `"${key}": ${value.join("\n")}`;
        }
      } else {
        generalErrorMessage += `"${key}": ${value.join("\n")}`;
      }
    });
  } else {
    generalErrorMessage = "Oops! Something happened. Please try again later.";
  }

  if (generalErrorMessage) {
    formikActions.setStatus({ generalErrorMessage });
  }
}

export default axiosDrfErrorHandler;
