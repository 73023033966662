import axios from "axios";
import qs from "qs";
import { getCookie } from "../utils";

const rootAPIUrl = "/api/v1";
const csrfToken = getCookie("csrftoken");

export const apiBaseSettings = {
  baseURL: rootAPIUrl,
  headers: { "X-CSRFTOKEN": csrfToken },
  withCredentials: true,
  paramsSerializer: (params: any) =>
    qs.stringify(params, { arrayFormat: "repeat" }),
};

export const contactAPI = axios.create({
  ...apiBaseSettings,
  baseURL: `${rootAPIUrl}/contacts`,
});
