import * as React from "react";
import * as ReactDOM from "react-dom";
import $ from "jquery";
import "popper.js";
import "bootstrap";
import { nanoid } from "nanoid";
import Glide from "@glidejs/glide";
import PhotoSwipe from "photoswipe";
import Choices from "choices.js";
import PhotoSwipeUIDefault from "photoswipe/dist/photoswipe-ui-default";

// import "@glidejs/glide/src/assets/sass/glide.core.scss";
// import "@glidejs/glide/src/assets/sass/glide.theme.scss";
// import "photoswipe/src/css/main.scss";
// import "photoswipe/src/css/default-skin/default-skin.scss";
// import "choices.js/public/assets/styles/choices.min.css";

import {
  checkIfMobile,
  getCurrentScreenSize,
  getCurrentWindowWidth,
} from "./utils";
import ContactForm from "./ContactForm";

const reactContactFormContainer = document.querySelector("#mainContactForm");
if (reactContactFormContainer) {
  ReactDOM.render(<ContactForm />, reactContactFormContainer);
}

document.addEventListener("DOMContentLoaded", function () {
  $('[data-toggle="popover"]').popover();

  // 로드된 상태에서 모바일이면 body 에 is mobile 달아줌
  if (checkIfMobile()) {
    document.body.classList.add("is-mobile");
  } else {
    document.body.classList.remove("is-mobile");
  }

  // choices.js 설정
  const choiceSelects = document.querySelectorAll(".choice-select");
  choiceSelects.forEach((selectEl: HTMLInputElement) => {
    const choiceSelect = new Choices(selectEl, {
      removeItemButton: true,
    });
    selectEl.addEventListener("change", function (event) {
      if (event.target) {
        const parentForm = (event.target as HTMLInputElement).closest("form");
        if (parentForm) {
          (parentForm as HTMLFormElement).submit();
        }
      }
    });
  });

  // 메인 슬라이더 콘트롤 버튼
  type CarouselControlBtnRoles = "pause" | "resume";
  const carouselControls = document.querySelectorAll(".carousel-control-btn");
  if (carouselControls) {
    carouselControls.forEach((el: HTMLButtonElement) => {
      el.addEventListener("click", function (e: MouseEvent) {
        const hashedTargetId = el.dataset.target;
        const currentRole = el.dataset.currentRole as CarouselControlBtnRoles;
        if (hashedTargetId) {
          if (currentRole === "pause") {
            $(hashedTargetId).carousel("pause");
            el.dataset.currentRole = "resume";
            const iconEl = el.querySelector(".material-icons");
            if (iconEl) {
              iconEl.innerHTML = "play_circle_outline";
            }
            const srEl = el.querySelector(".sr-only");
            if (srEl) {
              srEl.innerHTML = "슬라이드 이동 재개";
            }
          } else if (currentRole === "resume") {
            $(hashedTargetId).carousel("cycle");
            el.dataset.currentRole = "pause";
            const iconEl = el.querySelector(".material-icons");
            if (iconEl) {
              iconEl.innerHTML = "pause_circle_outline";
            }
            const srEl = el.querySelector(".sr-only");
            if (srEl) {
              srEl.innerHTML = "슬라이드 이동 멈춤";
            }
          }
        }
      });
    });
  }

  // 메인 네비게이션 모바일 대응
  $("#mainNavbarCollapsible")
    .on("show.bs.collapse", () => {
      $("#mainNav").addClass("mobile-collapsed");
    })
    .on("hidden.bs.collapse", () => {
      $("#mainNav").removeClass("mobile-collapsed");
    });

  // PhotoSwipe 설정
  const openImgGallery = (imgArray, startIndex = 0) => {
    const pswpElement = document.querySelectorAll(".pswp")[0] as HTMLElement;
    // define options (if needed)
    const options = {
      // history & focus options are disabled on CodePen
      focus: false,
      index: startIndex,
      showAnimationDuration: 0,
      hideAnimationDuration: 0,
      bgOpacity: 0.85,
      shareButtons: [
        {
          id: "download",
          label: "이미지 다운로드",
          url: "{{raw_image_url}}",
          download: true,
        },
      ],
    };
    const gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUIDefault,
      imgArray,
      options
    );
    gallery.init();
  };

  const imageContainerDatasetSetterCreator = (sImgElement: HTMLElement) => (
    width: number,
    height: number
  ) => {
    if (!sImgElement.hasAttribute("data-img-width")) {
      sImgElement.dataset.imgWidth = width.toString();
    }
    if (!sImgElement.hasAttribute("data-img-height")) {
      sImgElement.dataset.imgHeight = height.toString();
    }
  };

  // 스트림 블럭 안의 싱글 이미지 클릭
  const clickableImgContainers = document.querySelectorAll(
    ".clickable-img-container"
  );
  const imagesToOpen = [...Array.from(clickableImgContainers)];
  imagesToOpen.forEach((sImg: HTMLElement) => {
    const img = new Image();
    const setImageContainerDataset = imageContainerDatasetSetterCreator(sImg);
    img.onload = function () {
      const imgWidth = (this as HTMLImageElement).width;
      const imgHeight = (this as HTMLImageElement).height;
      setImageContainerDataset(imgWidth, imgHeight);
    };
    img.src = sImg.dataset.imgSrc;
    sImg.addEventListener("click", () => {
      openImgGallery([
        {
          w: sImg.dataset.imgWidth,
          h: sImg.dataset.imgHeight,
          src: sImg.dataset.imgSrc,
          title: sImg.dataset.imgCaption,
        },
      ]);
    });
  });

  const glideComponents = document.querySelectorAll(".glide.image-glide");

  glideComponents.forEach((glideItem: HTMLElement) => {
    //
    glideItem.id = `glide${nanoid(5)}`;
    const glideItemId = glideItem.id;
    const images = Array.from(glideItem.getElementsByTagName("img"));
    const imgArr = Array.from(images).map((imgEl, index) => {
      return {
        w: imgEl.dataset.imgWidth,
        h: imgEl.dataset.imgHeight,
        src: imgEl.dataset.imgSrc,
        title: imgEl.dataset.imgCaption,
      };
    });
    images.forEach((img, i) => {
      img.addEventListener("click", () => {
        openImgGallery(imgArr, i);
      });
    });
    const peekOptions = { before: 0, after: 0 };
    if (glideItem.dataset.slidePeekBefore) {
      peekOptions.before = parseInt(glideItem.dataset.slidePeekBefore, 10);
    }
    if (glideItem.dataset.slidePeekAfter) {
      peekOptions.after = parseInt(glideItem.dataset.slidePeekAfter, 10);
    }
    if (glideItem.dataset.slidePeek) {
      const peekSize = parseInt(glideItem.dataset.slidePeek, 10);
      peekOptions.before = peekSize;
      peekOptions.after = peekSize;
    }
    const glideOption = {
      // 슬라이드 개수
      ...(glideItem.dataset.slidePerView && {
        perView: parseInt(glideItem.dataset.slidePerView, 10),
      }),
      ...(glideItem.dataset.slideFocusAt && {
        focusAt: glideItem.dataset.slideFocusAt,
      }),
      // ...(glideItem.dataset.slideGap && { gap: glideItem.dataset.slideGap }),
      // peek: 0,
      // peek: peekOptions,
      type: "carousel" as const,
      breakpoints: {
        576: {
          perView: 1,
        },
      },
    };
    const glide = new Glide(`#${glideItemId}`, glideOption);
    glide.mount();
  });
});
